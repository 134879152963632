import MediaQuery from 'react-responsive'

const WORKSHOP_landscape = () => {
    return(
    <section>
        <div id="contentDiv" style={{backgroundColor: 'black'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <h1 style={{color: 'white', fontSize: '30px'}}>[ LANDSCAPE ]</h1>
                </div>
            </div>
        </section>
    );
};

export default WORKSHOP_landscape;